// https://de.reactjs.org/docs/code-splitting.html

import '../index.css';
import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { getRoute } from './Routing';

import { withMediaDetect } from './withMediaDetect';

const Header = lazy(() => import('../theme/Header'));
const Footer = lazy(() => import('../theme/Footer'));
const Home = lazy(() => import('../theme/Home'));
const Category = lazy(() => import('../theme/Category'));
const Contact = lazy(() => import('../theme/Contact'));
const ContactConfirmation = lazy(() => import('../theme/ContactConfirmation'));
const Product = lazy(() => import('../theme/Product'));
const Cms = lazy(() => import('../theme/Cms'));
const Cart = lazy(() => import('../theme/Cart'));
const Checkout = lazy(() => import('../theme/Checkout'));
const OrderConfirmation = lazy(() => import('../theme/OrderConfirmation'));
const Customer = lazy(() => import('../theme/customer/Customer'));
const Registration = lazy(() => import('../theme/customer/Registration'));
const CustomerForm = lazy(() => import('../theme/customer/CustomerForm'));
const OrderHistory = lazy(() => import('../theme/customer/OrderHistory'));
const OrderDetails = lazy(() => import('../theme/customer/OrderDetails'));
const Error404 = lazy(() => import('../theme/Error404'));

class App extends React.Component {

    componentDidMount() {
        this.props.fetchJsShop();
        this.props.fetchCategories();
        this.props.fetchJsCMS();
        this.props.fetchJsProducts();
        this.props.fetchCustomer();
        this.props.fetchNotifications();
        this.props.fetchRoutes();
        this.props.fetchShoppingCart();
    }

    renderContent() {

        const { jsshop, jsroutes } = this.props;
        const { urls } = jsshop;

        return (
            <Suspense fallback={<div></div>}>
                
                <div className='px-2 md:px-3 text-base'>

                    <Header mobile={this.props.mobile} />

                    <BrowserRouter>
                        <Routes>
                            <Route path={getRoute('default', jsroutes, [], urls.base_url).getRule()} element={<Home mobile={this.props.mobile} />} />
                            <Route path={getRoute('home', jsroutes, [], urls.base_url).getRule()} element={<Home mobile={this.props.mobile} />} />
                            <Route path={getRoute('contact', jsroutes, [], urls.base_url).getRule()} element={<Contact mobile={this.props.mobile} />} />
                            <Route path={getRoute('contact-confirmation', jsroutes, [], urls.base_url).getRule()} element={<ContactConfirmation mobile={this.props.mobile} />} />
                            <Route path={getRoute('category', jsroutes, [], urls.base_url).getRule()} element={<Category mobile={this.props.mobile} />} />
                            <Route path={getRoute('product', jsroutes, [], urls.base_url).getRule()} element={<Product mobile={this.props.mobile} />} />
                            <Route path={getRoute('cms', jsroutes, [], urls.base_url).getRule()} element={<Cms mobile={this.props.mobile} />} />
                            <Route path={getRoute('cart', jsroutes, [], urls.base_url).getRule()} element={<Cart mobile={this.props.mobile} />} />
                            <Route path={getRoute('checkout', jsroutes, [], urls.base_url).getRule()} element={<Checkout />} />
                            <Route path={getRoute('order-confirmation', jsroutes, [], urls.base_url).getRule()} element={<OrderConfirmation />} />
                            <Route path={getRoute('signin', jsroutes, [], urls.base_url).getRule()} element={<Customer />} />
                            <Route path={getRoute('registration', jsroutes, [], urls.base_url).getRule()} element={<Registration />} />
                            <Route path={getRoute('myaccount', jsroutes, [], urls.base_url).getRule()} element={<Customer />} />
                            <Route path={getRoute('profil', jsroutes, [], urls.base_url).getRule()} element={<CustomerForm mobile={this.props.mobile} />} />
                            <Route path={getRoute('password', jsroutes, [], urls.base_url).getRule()} element={<CustomerForm mobile={this.props.mobile} />} />
                            <Route path={getRoute('email', jsroutes, [], urls.base_url).getRule()} element={<CustomerForm mobile={this.props.mobile} />} />
                            <Route path={getRoute('address', jsroutes, [], urls.base_url).getRule()} element={<CustomerForm mobile={this.props.mobile} />} />
                            <Route path={getRoute('order-history', jsroutes, [], urls.base_url).getRule()} element={<OrderHistory />} />
                            <Route path={getRoute('order-details', jsroutes, [], urls.base_url).getRule()} element={<OrderDetails />} />
                            <Route path='*' element={<Error404 />} />
                        </Routes>
                    </BrowserRouter>

                </div>

                <Footer mobile={this.props.mobile} />
            </Suspense>
        );
    }

    render() {
        
        if (!this.props.jsshop || this.props.jsshop === undefined || JSON.stringify(this.props.jsshop) === "{}") {
            return <div className='min-h-screen flex flex-col justify-center justify-between'></div>;
        }

        const { page_status } = this.props.jsshop;

        if (page_status === "404") {
            return (
                <div className='min-h-screen flex flex-col justify-center justify-between'>
                    <Suspense fallback={<div></div>}>
                        <div className='px-2 md:px-10'>
                            <Header mobile={this.props.mobile} />
                            <Error404 />
                        </div>
                        <Footer mobile={this.props.mobile} />
                    </Suspense>
                </div>
            );
        }

        return (
            <div className='min-h-screen flex flex-col justify-center justify-between'>
                {this.renderContent()}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        jsshop: state.jsshop,
        jsroutes: state.routes,
    }
}

export default connect(mapStateToProps, { ...actions })(withMediaDetect(App));