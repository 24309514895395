import * as actionTypes from "../actions/types";
const relatedProductsReducer = (state = [], action) => {
    switch (action.type) {
        case actionTypes.FETCH_JSRELATED_PRODUCTS:
            return action.payload;
        default:
            return state;
    }
}

export default relatedProductsReducer;