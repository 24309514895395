const categoryReducer = (state={}, action) => {
    switch(action.type) {
        case 'FETCH_CATEGORY':
            return { ...state, ...{ [action.payload.id] : action.payload } };
        // case 'FETCH_CATEGORIES':
        //     return { ...state, ...action.payload[0] };
        case 'FETCH_CATEGORIES':
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export default categoryReducer;